<template>
  <div class="application-cache">
    <div class="application-cache-header">
      <div class="app">
        <app-logo hide-tooltip class="bg-white" :application="application" />
        <div class="name">{{ application.name }}</div>
      </div>
      <button class="main-button second-accent" @click="clearCache('ALL')">
        <v-svg h="1.4em" name="empty" />{{ allCachesEmptied ? 'Cache vidé' : 'Vider le cache' }}
      </button>
      <div> {{ infos?.dbsize }} valeurs </div>
      <div class="infos" :class="{ alert: memoryAlert }">
        <v-svg name="data-usage" :class="{ spin: loading }"></v-svg>
        {{ infos?.used_memory_human }} / {{ infos?.maxmemory_human }}
      </div>
      <div :class="['usage', { alert: memoryAlert }]" :style="chartStyle">
        <div class="bar-used"></div>
        <div class="bar-free"></div>
      </div>
      <div :class="['used-memory', { alert: memoryAlert }]"> {{ (usedMemory * 100).toFixed(1) }}% utilisé </div>
    </div>
    <div class="subcaches">
      <div v-for="subcache in cache.caches" :key="subcache" class="subcache">
        {{ subcache }}
        <button v-if="!emptiedCaches.includes(subcache)" class="second-button second-accent small" @click="clearCache(subcache)">
          Vider
        </button>
        <button v-else class="main-button second-accent small">Vidé</button>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, inject, computed, watch, onMounted, onUnmounted } from 'vue'
  import useApplicationStore from '@/store/application.store'

  import { http, extendWithAuthorization } from '@/http'

  export default {
    name: 'ApplicationCache',
    props: {
      cache: {
        type: Object,
        required: true
      }
    },
    setup(props) {
      const applicationStore = useApplicationStore()
      const application = computed(() => applicationStore.getAppById(props.cache.app) ?? { name: props.cache.app })

      const infos = ref(null)
      const loading = ref(true)
      const fetchInfos = async () => {
        loading.value = true
        const extended = await extendWithAuthorization(http)
        infos.value = await extended.get(`cache/${props.cache.project}`).json()
        loading.value = false
      }

      let interval = null
      onMounted(async () => {
        fetchInfos()
        interval = setInterval(fetchInfos, 30000)
      })

      onUnmounted(() => {
        clearInterval(interval)
      })

      const usedMemory = computed(() => infos.value?.used_memory / infos.value?.maxmemory ?? 0)

      const memoryAlert = computed(() => usedMemory.value > 0.85)

      const chartStyle = computed(() => ({
        gridTemplateColumns: `${Math.ceil(usedMemory.value * 100)}% 1fr`
      }))

      const emptiedCaches = ref([])

      const allCachesEmptied = computed(() => {
        return props.cache.caches.length ? emptiedCaches.value.length === props.cache.caches.length : emptiedCaches.value.includes('ALL')
      })

      const clearCache = async cache => {
        if (!confirm(`Vider le cache ${cache} de ${application.value.name} ?`)) return

        if (cache === 'ALL' && props.cache.caches.length) {
          emptiedCaches.value = props.cache.caches
        } else emptiedCaches.value.push(cache)
        setTimeout(() => {
          emptiedCaches.value = cache === 'ALL' ? [] : emptiedCaches.value.filter(c => c !== cache)
        }, 30000)

        const extended = await extendWithAuthorization(http)
        await extended.post(`cache/${props.cache.project}`, { searchParams: { cache } })
      }

      return {
        application,
        allCachesEmptied,
        infos,
        memoryAlert,
        usedMemory,
        chartStyle,
        loading,
        clearCache,
        emptiedCaches
      }
    }
  }
</script>

<style lang="scss" scoped>
  .application-cache {
    background: hsla(var(--hsla-black), 0.05);
    padding: 16px;
    border-radius: 16px;
    position: relative;

    &-header {
      display: grid;
      grid-template-columns: 1fr auto;
      justify-content: space-between;
      margin-bottom: 1rem;
      align-items: center;
      gap: 4px;

      .app {
        display: flex;
        align-items: center;
        .name {
          margin-left: 8px;
          @extend %font-title-4;
        }
      }

      .infos {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &.alert {
          color: var(--color-destructive);
          font-weight: 600;
        }
        .v-svg {
          margin-right: 0.6em;
          &.spin {
            animation: spin 1s infinite;
          }
        }
      }
      .usage {
        grid-column: 1 / 3;
        height: 4px;
        width: 100%;
        border-radius: 2px;
        display: grid;
        column-gap: 2px;
        overflow: hidden;
        > * {
          height: 100%;
          width: 100%;
        }
        .bar-used {
          background: var(--color-main-accent-darker);
        }
        .bar-free {
          background: var(--color-main-accent);
        }
        &.alert {
          .bar-used {
            background: var(--color-destructive-darker);
          }
          .bar-free {
            background: var(--color-destructive);
          }
        }
      }
      .used-memory {
        @extend %font-caption-2;
        color: var(--color-main-accent-darker);
        &.alert {
          color: var(--color-destructive);
        }
      }
    }

    .subcaches {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4px;
      .subcache {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:hover {
          font-weight: 600;
        }
      }
    }
  }
  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
