<template>
  <div class="caches-view">
    <div class="head">
      <input v-model="filter" class="main-input" type="text" placeholder="Rechercher par nom" />
    </div>
    <div class="applications">
      <application-cache v-for="cache in filteredCaches" :key="cache.id" :cache="cache" />
    </div>
  </div>
</template>

<script>
  import { computed, ref } from 'vue'
  import { globalProperties } from '@/main'
  import ApplicationCache from '@/components/cache/ApplicationCache'
  import useApplicationStore from '@/store/application.store'
  import useCacheStore from '@/store/cache.store'

  export default {
    name: 'Caches',
    components: { ApplicationCache },
    setup() {
      const cacheStore = useCacheStore()
      cacheStore.fetchCaches()
      const { caches } = cacheStore

      const applicationStore = useApplicationStore()
      const roles = globalProperties.roles

      const filter = ref('')
      const filteredCaches = computed(
        () =>
          caches.value?.filter(
            c =>
              roles.global?.includes('super-admin') ||
              roles.global?.includes('admin') ||
              roles['smartboard-v2']?.includes('all-apps') ||
              (applicationStore.applications.value?.findIndex(a => a.id === c.app) >= 0 && c.app.toUpperCase().includes(filter.value.toUpperCase()))
          ) ?? []
      )

      return { filteredCaches, filter }
    }
  }
</script>

<style lang="scss" scoped>
  .caches-view {
    padding: 2rem;
    overflow: auto;

    .head {
      display: flex;
      justify-content: space-between;

      input {
        width: 400px;
      }
    }

    .applications {
      margin: 24px 0;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 24px;
    }
  }
</style>
